import api from "@/services/apiService"

class MarketSettingService {

  getMarketSettingReceive() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'market-setting/receive')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  updateMarketSettingReceive(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'market-setting/receive', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * ข้อมูลการรับของหวยชุด
   */
  getMarketSetsReceive() {
    return new Promise((resolve, reject) => {
      api.get(process.env.VUE_APP_API_MAIN + 'market-setting/sets-receive')
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }

  /**
   * บันทึกข้อมูลรับของหวยชุด
   */
  updateMarketSetsReceive(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_API_MAIN + 'market-setting/sets-receive', data)
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        reject(error)
      })
    })
  }
}

export default new MarketSettingService()
